


import req from './https'



//  足跡
export const apiGetFootprintPages = (params) => req('post', '/Questionnire/GetFootprintPages', params)
export const apiAddFootprint = (params) => req('post', '/Questionnire/AddFootprint', params)
export const apiEditFootprint = (params) => req('put', '/Questionnire/EditFootprint', params)
export const apiDeleteFootprint = (params) => req('delete', '/Questionnire/DeleteFootprint', params)



//  足跡 (外部疫調)
export const apiGetExternalFootprintPages = (params) => req('post', '/ExternalQnr/GetFootprintPages', params)
export const apiAddExternalFootprint = (params) => req('post', '/ExternalQnr/AddFootprint', params)
export const apiEditExternalFootprint = (params) => req('put', '/ExternalQnr/EditFootprint', params)
export const apiDeleteExternalFootprint = (params) => req('delete', '/ExternalQnr/DeleteFootprint', params)



